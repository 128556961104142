import React, { useEffect, useState } from 'react'
import { ProductCard,  ProductImg, ProductH2, ProductH2Wrapper,  ProductP, ProductImgContainer, ProductWords, ProductBlank, ProductWordsDiv, ProductTypeDiv } from './ProductSectionElements'
import {animateScroll as scroll} from 'react-scroll'
import { motion } from 'framer-motion'
// import Carousel from 'react-elastic-carousel'



const ProductSection = ({ ...props }) => {
    // console.log(props);
    
    const text = "Add To Cart";
    const [buttonText, setButtonText] = useState(text);
    useEffect(() => {
        const timer = setTimeout(() => {
            setButtonText(text);
        }, 3000);
        return () => clearTimeout(timer);
    }, [buttonText])

    const toTop = () => {
        scroll.scrollToTop()
    };

    return (
        <motion.div whileTap = {{scale:0.9}}>
        <ProductCard smooth = "true" to = {"/plant/" + props.id} key={props.title} onClick = {toTop}>
            <ProductImgContainer whileHover = {{scale: 1.1, transition: { duration: 0.3 }}}>
                <ProductImg src={props.imageWebpURL} alt = "hehehe"/>
            </ProductImgContainer>
            <ProductTypeDiv>
            {props.type !== "" ? (
                <ProductP><b>{props.type}</b></ProductP>
            ) : (
                <ProductBlank></ProductBlank>
            )}
            </ProductTypeDiv>
            <ProductH2Wrapper>
            <ProductH2>
                {props.title} <br/>{props.price1}
            </ProductH2>
            <ProductWordsDiv>
            {props.inStock !== "true" ? (
                <ProductWords>Sold Out. Check back for updates!</ProductWords>
            ) : 
            (
                <ProductBlank>&nbsp;</ProductBlank>
            )}
            </ProductWordsDiv>
            </ProductH2Wrapper>
        </ProductCard>
        </motion.div>
    )
}

export default ProductSection