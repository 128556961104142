import React from 'react'
import { NavbarContainer, NavLogo, NavTitle } from '../Navbar/NavbarElements'
import { Container, TopWrapper, TickImg, MiddleWrapper, MiddleWrapperTextBold, MiddleWrapperText, MiddleWrapperText1, ButtonWrap, Button, BackLink } from './PurchasedPageElements'
import {FaInstagram} from 'react-icons/fa'
import {motion} from 'framer-motion'
import {animateScroll as scroll} from 'react-scroll'

const PurchasedPageSection = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
    };

    return (
        <>
            <NavbarContainer>
                <NavLogo to = "/" />
                <NavTitle>P E A C E F U L<br/>T A B L E T O P</NavTitle>
            </NavbarContainer>
            <Container initial = {{x: '100vw'}} animate = {{x: 0}} transition = {{type: "spring", delay: 1}}>
                <TopWrapper>
                    <TickImg/>
                </TopWrapper>
                <MiddleWrapper>
                    <MiddleWrapperTextBold>Your order has been submitted!</MiddleWrapperTextBold>
                    <MiddleWrapperText>We will contact you via SMS as soon as possible regarding payment and delivery.</MiddleWrapperText>
                    <MiddleWrapperText1>For enquires, reach us on Instagram. </MiddleWrapperText1>
                    <MiddleWrapperText1>Stay Peaceful, folks!</MiddleWrapperText1>
                    <motion.div whileTap = {{scale:0.8}}>
                    <ButtonWrap>
                        <Button href='https://www.instagram.com/peaceful.tabletop/' target="_blank"><FaInstagram/> &nbsp;&nbsp;Peaceful Tabletop</Button>
                    </ButtonWrap>
                    </motion.div>
                    <ButtonWrap>
                        <BackLink to = "/" onClick = {toggleHome} >Back to Home</BackLink>
                    </ButtonWrap>
                </MiddleWrapper>
            </Container>
        </>
    )
}

export default PurchasedPageSection
