import React, { useState, useEffect } from 'react'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavTitle, CartImage} from './NavbarElements'
import { FaBars } from 'react-icons/fa'
// import { AiOutlineShoppingCart } from "react-icons/ai";
import {animateScroll as scroll} from 'react-scroll'
// import { motion } from 'framer-motion'

const Navbar = ({toggle}) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        }
        else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop()
    };


    // constructor(props){
    //     super(props);
    //     this.cartRef = React.createRef();
    // }

    return (
        <>
            <Nav scrollNav={scrollNav} 
                initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0.6, duration: 1.5}}>
                <NavbarContainer>
                    <NavLogo to = "/" onClick = {toggleHome}/>
                    <NavTitle>P E A C E F U L<br/>T A B L E T O P</NavTitle>
                    <NavMenu>
                    <CartImage to = "/cart"/>
                    <MobileIcon onClick = {toggle}>
                        <FaBars/>
                    </MobileIcon>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar
