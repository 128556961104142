import React, {useState, useContext, useEffect} from 'react'
import InfoSection from '../components/InfoSection'
import LandingSection from '../components/LandingSection'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { Overlay, ProductContainer, ProductH1, ProductWrapper } from './GeneralStyles'
import Context from '../store/Context'
import FooterSection from '../components/FooterSection'
import ProductSection from '../components/ProductSection'
// const ProductSection = React.lazy(() => import('../components/ProductSection'));
const Home = () => {

    const [isOpen, setIsOpen] = useState(false);
    
    const toggle = () => {
        setIsOpen(!isOpen)
    };

    const context = useContext(Context);

    useEffect(() => {
        // console.log(context);
        // console.log(context.products)
    }, [context]);

    return (
        <>
            <Sidebar isOpen = {isOpen} toggle = {toggle} />
            {isOpen && <Overlay/>}
            <Navbar toggle = {toggle}/>
            <LandingSection/>
            <InfoSection/>
            <ProductContainer id = 'shop' initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0.4, duration: 1.5}}>
                <ProductH1>SHOP</ProductH1>
                <ProductWrapper>
                {context.products.map((p) => (
                        <ProductSection key = {p.id} id = {p.id} imageJpgURL = {p.imageJpgURL} imageWebpURL = {p.imageWebpURL} price = {p.price}
                            price1 = {p.price1} title = {p.title} type = {p.type} inStock = {p.inStock} addProductToCart = {context.addProductToCart}/>
                    ))}
                </ProductWrapper>
            </ProductContainer>
            <FooterSection/>
        </>
    )
}

export default Home
