import React, {useContext} from 'react'
// import Navbar from '../components/Navbar';
import Context from '../store/Context'
// import Sidebar from '../components/Sidebar'
// import { Overlay } from './GeneralStyles'
import PlantSection from '../components/PlantSection'

const PlantsPage = () => {
    

    // const [isOpen, setIsOpen] = useState(false);
    
    // const toggle = () => {
    //     setIsOpen(!isOpen)
    // };

    const context = useContext(Context);
    // console.log(context);

    return (
        <>
        {/* <Sidebar isOpen = {isOpen} toggle = {toggle}/>
            {isOpen && <Overlay/>} */}
        {/* <Navbar toggle = {toggle}/> */}
        <PlantSection addProductToCart = {context.addProductToCart} products = {context.products}/>
        </>
    )
}

export default PlantsPage;