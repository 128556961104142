import React from 'react'
import { CartContainer, CartIcon, CloseIcon, TopWrapper, CartImg, TopWrapperText, HLine, MiddleWrapper, MiddleWrapper1, MiddleWrapperText, MiddleWrapperText1, MiddleWrapperText2, ButtonWrap, Button, ItemsWrap, ItemWrapText, ItemWrapText1, ItemWrapImg, ButtonWrap1, Button1, MiddleWrapperSpanText } from './CartSectionElements'
import {animateScroll as scroll} from 'react-scroll'
import { BsTrash } from "react-icons/bs";
import { motion } from 'framer-motion';


const CartSection = ({ carts, removeProductFromCart, clearCart }) => {
    var totalPrice = 0;
    var quantity = 0;
    for(var i = 0; i < carts.length; i ++){
        totalPrice += (carts[i]['product']['price'] * carts[i]['quantity']);
        quantity += carts[i]['quantity'];
    }
    let word = "";
    if(quantity === 1){
        word = "item";
    }
    else{
        word = "items";
    }

    const toggleHome = () => {
        scroll.scrollToTop()
    };
    return (
        <>
            <CartContainer initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0, duration: 1.5}}>
                <CartIcon to="/" onClick = {toggleHome}>
                    <CloseIcon />
                </CartIcon>
                <TopWrapper>
                    <CartImg />
                    <TopWrapperText>
                        Your Shopping Cart
                    </TopWrapperText>
                    <HLine />
                </TopWrapper>
                {carts.length === 0 ? (
                    <MiddleWrapper>
                        <MiddleWrapperText>You have no items in your cart. Click <MiddleWrapperSpanText to = "/">here</MiddleWrapperSpanText> to go back.</MiddleWrapperText>
                    </MiddleWrapper>
                ) : ( 
                    <>
                        <MiddleWrapper>
                            <MiddleWrapperText>You have <b>{quantity}</b> {word} in your shopping cart</MiddleWrapperText>
                            {/* <MiddleWrapper1>
                                <MiddleWrapperText1>SubTotal Order</MiddleWrapperText1>
                                <MiddleWrapperText1> ${totalPrice}</MiddleWrapperText1>
                            </MiddleWrapper1>
                            <MiddleWrapper1>
                                <MiddleWrapperText1>Delivery Charge</MiddleWrapperText1>
                                <MiddleWrapperText2> + $0.00</MiddleWrapperText2>
                            </MiddleWrapper1>
                            <hr /> */}
                        </MiddleWrapper>
                        {/* <motion.div whileTap = {{scale: 0.8}}>
                        <ButtonWrap>
                            <Button to ="/checkout">Place Order</Button>
                        </ButtonWrap>
                        </motion.div> */}
                        {carts.map(({product, quantity}) =>(
                            <ItemsWrap key = {product.id}>
                            <ItemWrapText>X {quantity} <br/><BsTrash onClick = {() => removeProductFromCart(product.id)} style={{ paddingTop: "20px", paddingRight: "20px", fontSize: "44px", pointer: "cursor" }} /></ItemWrapText>
                            <ItemWrapImg src={product.imageWebpURL} alt="hehehe"/>
                            <ItemWrapText1><b>{product.title}</b><br />${product.price * quantity}</ItemWrapText1>
                            </ItemsWrap>
                        ))}
                        <motion.div whileTap = {{scale: 0.8}}>
                        <ButtonWrap1>
                            <Button1 onClick = {() => clearCart()}>
                                Remove all products
                            </Button1>
                        </ButtonWrap1>
                        </motion.div>
                        <MiddleWrapper>
                            <MiddleWrapper1>
                                <MiddleWrapperText1>SubTotal Order</MiddleWrapperText1>
                                <MiddleWrapperText1> ${totalPrice}</MiddleWrapperText1>
                            </MiddleWrapper1>
                            <MiddleWrapper1>
                                <MiddleWrapperText1>Delivery Charge</MiddleWrapperText1>
                                <MiddleWrapperText2> + $0.00</MiddleWrapperText2>
                            </MiddleWrapper1>
                            <hr />
                        </MiddleWrapper>
                        <motion.div whileTap = {{scale: 0.8}}>
                        <ButtonWrap>
                            <Button to = "/checkout">Place Order</Button>
                        </ButtonWrap>
                        </motion.div>
                    </>
                )}
            </CartContainer>
        </>
    )
}

export default CartSection
