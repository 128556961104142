import styled from "styled-components";
import { motion } from 'framer-motion'

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 500;
`;

export const ProductContainer = styled(motion.div)`
    top: 0;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    text-decoration: none;
    padding-bottom: 70px;
    margin: 0;
    /* padding-left: 40px; */

    @media screen and (max-width: 960px){
        height: auto;
        padding-bottom: 60px;

    }

    @media screen and (max-width: 768px){
        height: auto;
        padding-bottom: 50px;

    }

    @media screen and (max-width: 480px){
        height: auto;
        padding-bottom: 30px;

    }
`;

export const ProductWrapper = styled.div`
    max-width: 1000px;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 24px;
    padding: 0;
    text-decoration: none;
    justify-content: center;



    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        /* width: 100%; */
        grid-template-columns: 1fr 1fr;
        /* padding: 0 5px; */
        grid-row-gap: 0px;        
        grid-column-gap: 10px;


    }
    @media screen and (max-width: 530px){
        width: 100%;
        grid-template-columns: 1fr 1fr;
        /* padding: 0 5px; */
        grid-row-gap: 0px;        
        grid-column-gap: 5px;
        /* padding-left: 5px; */


    }
`;

export const ProductH1 = styled.p`
    padding-top: 40px;
    font-size: 40px;
    color: black;
    margin-bottom: 0px;
    font-weight: 500;

    @media screen and (max-width: 540px){
        font-size: 32px;
    }
`;