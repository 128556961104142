import React, {useEffect} from 'react'
import { AboutContainer, AboutWrapper, AboutText, AboutTextHeading, AboutTextGreen, ImgWrapper, ButtonWrap, Button, BackLink } from './AboutSectionElements'
import {animateScroll as scroll} from 'react-scroll'
import { motion } from 'framer-motion';

const AboutSection = () => {

    useEffect(() => {
        window.scroll(0,0);
    });

    const toggleHome = () => {
        scroll.scrollToTop()
    };

    return (
        <>
            <AboutContainer initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0.4, duration: 1.5}}>
                <AboutWrapper>
                    <AboutTextHeading>
                        We're Peaceful Tabletop. 
                    </AboutTextHeading>
                    <AboutText>
                        We focus on creating <AboutTextGreen>artisanal botanical pieces</AboutTextGreen> that fit on your work desk or work spaces.
                        Singapore is where we call home. 
                    </AboutText>
                    <AboutText>
                        All our plants are curated to be calming to look at and suitable to be grown in indoor spaces. 
                    </AboutText>
                    <ImgWrapper/>
                    <AboutText>
                        We’ll always provide free doorstep delivery and a zen-style ceramic plate.  
                    </AboutText>
                    <AboutText>
                        Please allow for 5-9 working days while our green-thumbs work on your pieces. 
                    </AboutText>
                    <ButtonWrap>
                        <motion.div whileTap = {{scale: 0.8}}>
                        <Button smooth = "true" to = "/#shop" >View the Collection</Button>
                        </motion.div>
                    </ButtonWrap>
                    <ButtonWrap>
                        <motion.div whileTap = {{scale: 0.8}}>
                        <BackLink to = "/" onClick = {toggleHome}>
                            Back to Home
                        </BackLink>
                        </motion.div>
                    </ButtonWrap>
                </AboutWrapper>
            </AboutContainer>
        </>
    )
}

export default AboutSection
