import styled from "styled-components";
import Img from '../../images/into.webp'
import { motion } from "framer-motion";

export const InfoContainer = styled(motion.div)`
    background: url(${Img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 15% 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 30px; */
    height: 800px;
    position: relative;
    z-index: -1;

    @media screen and (max-width: 768px){
        height: 600px;
        background-size: cover;
        background-position: 25% 75%;

    }

    @media screen and (max-width: 540px){
        height: 400px;
        background-size: cover;
        background-position: 10% 75%;
    }

    @media screen and (max-width: 480px){
        height: 400px;
        background-size: cover;
        background-position: 15% 75%;
    }

    @media screen and (max-width: 280px){
        height: 400px;
        background-size: cover;
        background-position: 15% 75%;
    }

`;

// export const InfoImage = styled.img`
//     background: url(${Img});
//     background-size: contain;
//     background-repeat: no-repeat;
//     justify-content: flex-end;
//     height: 100%;
//     width: 100%;
//     position: absolute;
// `;

export const InfoContent = styled.div`
    /* top: 170px; */
    right: 70px;
    z-index: 999;
    width: 550px;
    justify-content: flex-end;
    position: absolute;
    padding: 8px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width:764px){
        right: 10px;
        width: 170px;
        top: 80px;
        padding-left: 0px;
        padding-right: 14px;
    }

    @media screen and (max-width: 540px){
        right: 30px;
        width: 170px;
        top: 45px;
        padding-left: 0px;
        padding-right: 20px;
    }

    @media screen and (max-width: 480px){
        right: 20px;
        width: 170px;
        top: 100px;
        padding-left: 15px;
        padding-right: 18px;

    }

    @media screen and (max-width: 280px){
        /* right: 10px; */
        padding: 0;
        width: 100px;
        padding-left: 0px;
        padding-right: 14px;
    }

`;

export const InfoTitleH1 = styled.p`
    color: black;
    font-size: 40px;
    margin-bottom: 15px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width:764px){
        font-size: 22px;
    }

    @media screen and (max-width: 480px){
        font-size: 16px;
    }

    @media screen and (max-width: 280px){
        font-size: 14px;
    }
`;


export const InfoP = styled.p`
    /* margin-top: 24px; */
    /* margin-bottom: 24px; */
    color: black;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
    font-weight: lighter;
    line-height: 1.5;


    @media screen and (max-width:764px){
        font-size: 14px;
    }

    @media screen and (max-width: 480px){
        font-size: 12px;
    }

    @media screen and (max-width: 280px){
        font-size: 10px;
    }
`;