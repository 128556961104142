import styled from "styled-components";
import { HashLink as LinkH } from 'react-router-hash-link';
import { motion } from "framer-motion";
// import {Link as LinkR} from 'react-router-dom';
// import Img from "react-webp-image";

export const PlantContainer = styled(motion.div)`
    background-color: white;
    /* align-items: center; */
    height: 100%;
    /* min-height: 100vh; */

`;



export const PlantWrapper = styled.div`
    position: relative;
    /* top: 80px; */
    z-index: 1;
    height: 100%;
    width: 100%;
    padding-left: 90px;
    padding-right: 120px;
    /* max-width: 1100px; */
    /* margin-right: auto;
    margin-left: auto; */
    /* padding: 0 50px; */
    /* align-items: center; */
    /* text-align: center ; */
    
    @media screen and (max-width: 768px){
        height: 100%;
        padding-left: 20px;
        padding-right: 30px;
    }
`;


export const PlantRow = styled.div`

    /* justify-content: left; */
`;


export const TextWrapper = styled.div`
    /* max-width: 540px; */
    padding-top: 0;
    padding-bottom: 30px;
    /* text-align: center; */
    
`

export const TopLine = styled.p`
    color: black;
    font-size: 38px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: 600;

    @media screen and (max-width: 768px){
        font-size: 26px;

    }
`;


export const TopLine2 = styled.p`
    color: #808080;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: 600;

    @media screen and (max-width: 768px){
        font-size: 10px;
    }


`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 20px;
    color: black;

    @media screen and (max-width: 480px){
        font-size: 28px;
    }
`

export const Heading1 = styled.h1`
    margin-bottom: 20px;
    font-size: 24px;
    color: #33BB30;
    display: flex;
    align-items: center;

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
    @media screen and (max-width: 280px){
        font-size: 12px;
    }
`
export const Subtitle = styled.p`
    /* max-width: 440px; */
    margin-top: 20px;
    margin-bottom: 35px;
    font-size: 24px;
    color: black;
    font-weight: 600;

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`
export const Subtitle1 = styled.p`
    /* max-width: 440px; */
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 22px;
    color: black;
    font-weight: 600;
    line-height: 1.7;

    @media screen and (max-width: 480px){
        font-size: 16px;
    }
`

export const SubtitleHeading = styled.p`
    /* max-width: 440px; */
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    color: black;
    font-weight: 700;
`

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
`;

export const ImgWrap = styled.div`
    /* max-width: 555px; */
    width:100%;
    height: 100%;
    position: relative;
    padding-top: 25px;
    justify-content: center;
    /* border: 1px solid black; */
    margin-bottom: 16px;
    /* padding-left: 20px; */

`;

export const Image = styled.img`
    height: 500px;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @media screen and (max-width: 767px){
        height: 250px;
    }
`;

export const Button = styled.button`
    border-radius: 5px;
    padding: 11px 22px;
    font-weight: 500;
    font-size: 28px;
    background-color: #33BB30;
    color: white;
    white-space: nowrap;
    outline: none;
    border:none;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;


    @media screen and (max-width: 768px){
        padding: 8px 14px;
        font-size: 22px;
    }

`;
export const ButtonDisabled = styled.button`
    border-radius: 5px;
    padding: 11px 22px;
    font-weight: 500;
    font-size: 26px;
    background-color: #33BB30;
    color: white;
    white-space: nowrap;
    outline: none;
    border:none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;

   @media screen and (max-width: 768px){
        padding: 6px 12px;
        font-size: 22px;
    }

`;
export const ButtonWrap2 = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
`;

export const Button2 = styled(LinkH)`
    text-decoration:none;
    border-radius: 5px;
    padding: 11px 22px;
    font-weight: 500;
    font-size: 28px;
    background-color: white;
    color: black;
    white-space: nowrap;
    outline: none;
    border: 1px black solid;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;

    @media screen and (max-width: 768px){
        padding: 8px 14px;
        font-size: 22px;
    }

`;

export const InlineImage = styled.img`
    width: 29px;
    height: 26px;
    background-size: contain;

    @media screen and (max-width: 480px){
        width: 22px;
        height: 23px;
    }
`

export const InlineImage1 = styled.img`
    width: 29px;
    height: 26px;
    background-size: contain;

    @media screen and (max-width: 480px){
        width: 22px;
        height: 16px;
    }
`

export const CarouselButtonRight = styled(motion.button)`
    top: calc(50% - 20px);
    position: absolute;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
    right: 10px;
    /* transform: scale(-1); */

`

export const CarouselButtonLeft = styled(motion.button)`
    top: calc(50% - 20px);
    position: absolute;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
    left: 10px;
`