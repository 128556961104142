import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import { HashLink as LinkH } from 'react-router-hash-link';
// import Img1 from '../../images/Instagram.png'
// import Img2 from '../../images/Carousell.png'
// import Img3 from '../../images/Facebook.png'


export const FooterContainer = styled.footer`
    border-top: 1px black solid;
    background: white;
`;

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width:768px){
        padding: 24px 12px;
    }
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;
    @media screen and (max-width: 820px){
        padding-top: 32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const FooterLinksItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 200px;
    box-sizing: box;
    color: black;

    @media screen and (max-width: 420px){
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const FooterLinkTitle = styled.h1`
    font-size: 20px;
    margin-bottom: 16px;
`

export const FooterLink = styled(LinkH)`
    cursor: pointer;
    color: black;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 15px;

/* 
    &:hover {
        color: white;
        transition: 0.3s ease-in-out;
    } */
`;

export const FooterLink1 = styled(LinkR)`
    cursor: pointer;
    color: black;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 15px;


    /* &:hover {
        color: white;
        transition: 0.3s ease-in-out;
    } */
`;

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`;

export const SocialLogo = styled.p`
    color: #000;
    justify-self: start;
    /* cursor: pointer; */
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
`;

export const WebsiteRights = styled.small`
    color: black;
    margin-bottom: 16px;
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
`;

export const SocialIcon = styled.div`
    display: inline;
    padding-bottom: 20px;
`

export const SocialIconLink = styled.a`
    color: #000;
    font-size: 24px;
    text-decoration: none;
    display: inline;
    
`

export const SocialIconSpan = styled.span`
    color: #000;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;
    top: -10px;
    /* padding-bottom: 10px; */
    position: relative;
`
export const SocialIconImg = styled.img`
    width: 26px;
    height: 26px;
`

// export const SocialIconLink1 = styled.a`
//     background-image: url(${Img1});
//     height: 24px;
//     width: 24px;
//     background-position: center;
//     background-repeat: no-repeat;
//     object-fit: contain;
// `

// export const SocialIconLink2 = styled.a`
//     background-image: url(${Img2});
//     height: 24px;
//     width: 24px;
// `

// export const SocialIconLink3 = styled.a`
//     background-image: url(${Img3});
//     height: 24px;
//     width: 24px;
// `