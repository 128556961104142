import styled from "styled-components";
import {FaTimes} from 'react-icons/fa';
// import {Link as LinkS} from 'react-scroll';
import {Link as LinkR} from 'react-router-dom';
// import { motion } from "framer-motion";
import { HashLink as LinkH } from 'react-router-hash-link';

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 45%;
    height: 100%;
    background: white;
    display:grid;
    align-items: center;
    top: 0;
    right: 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0.23);
    transition: 0.2s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    right: ${({isOpen}) => (isOpen ? '0': '-100%')};

    @media screen and (max-width: 768px){
        width: 70%;
    }
`;

export const CloseIcon = styled(FaTimes)`
    color: #000;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    outline: none;
`;

export const SidebarWrapper = styled.div`
    color: #000;
    justify-content: flex-start;

`;

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6,85px);
    text-align: center;

    @media screen and (max-width: 480px){
        grid-template-rows: repeat(6,60px);
    }
`;

export const SidebarLink = styled(LinkH)`
    display: flex;
    align-items: center;
    padding-left: 30px;
    justify-content: start;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #000;
    cursor: pointer;
    padding-top: 20px;
    font-weight: 390;

    &:hover {
        color: grey;
        transition: 0.2s ease-in-out;
    }
`;

export const SidebarLink1 = styled(LinkR)`
    display: flex;
    align-items: center;
    padding-left: 30px;
    justify-content: start;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #000;
    cursor: pointer;
    padding-top: 20px;
    font-weight: 390;

    &:hover {
        color: grey;
        transition: 0.2s ease-in-out;
    }
`;

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: start;

    position: relative;
`;

export const SidebarRoute = styled.div`

    border-radius: 50%;
    background: transparent;
    white-space: nowrap;
    color: black;
    outline: none;
    border: none;
    padding: 10px 22px;
    font-size: 25px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    &:hover {
        color: white;
        transition: 0.2s ease-in-out;
    }
`;

export const SideCartCount = styled.span`
    position: absolute;
    align-items: center;
    bottom: 9px;
    letter-spacing: 1px;
    /* padding-left: 10px; */
    margin-left: 2px;
    padding: 3px 11px; 
    border-radius: 45%;
    border-color: black;
    background: white;
    color: black;
    font-size: 1.5rem;
    font-weight: 600;

    @media screen and (max-width: 480px){
        bottom: -20px;
    }

`;


export const CartImg = styled.img`
    width: 37px;
    height: 37px;
    background-size: contain;

`;