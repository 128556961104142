import styled from "styled-components";
import {FaTimes} from 'react-icons/fa';
import {Link as LinkR} from 'react-router-dom';
import Img from '../../images/cart.webp'
import Trash from '../../images/trash.webp'
import { motion } from "framer-motion";

export const CartContainer = styled(motion.div)`
    z-index: 1000;
    width: 100%;
    background: white;
    top: 0;
    right: 0;
    padding: 15px 25px 25px 15px;
    position: fixed;
    overflow: auto;
    height: 100%;
    transition: 0.2s ease-in-out;

`;

export const CloseIcon = styled(FaTimes)`
    color: #000;
    top: 1.2rem;
    right: 1.5rem;
    font-size: 2rem;

`;

export const CartIcon = styled(LinkR)`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    outline: none;
    text-align: center;
    cursor: pointer;
    z-index: 999;
`;

export const TopWrapper = styled.div`
    height: 250px;
    width: 100%;
    /* padding: 0 20px; */
    padding-top: 40px;
    justify-content: center;
    display: block;
    position: relative;
`

export const CartImg = styled.div`
    background: url(${Img});
    padding-bottom: 30px;
    height: 100px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;

`;

export const TopWrapperText = styled.p`
    top: 120px;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    text-align: center;
`;

export const HLine = styled.hr`
    color: black;
    top: 140px;
    position: relative;
`;

export const MiddleWrapper = styled.div`
    position: relative; 
    justify-content: flex-start;
    padding-left: 60px;
    padding-bottom: 30px;

    @media screen and (max-width: 768px){
        padding-left: 20px;

    }

`;

export const MiddleWrapper1 = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    padding-bottom: 40px;
    
`;


export const MiddleWrapperText = styled.p`
    font-size: 30px;
    padding-bottom: 30px;
    line-height: 1.3;
    /* @media screen and (max-width: 768px){
        padding-bottom: 0;
    } */

    @media screen and (max-width: 768px){
        font-size: 26px;
    }
`
export const MiddleWrapperSpanText = styled(LinkR)`
    font-size: 30px;
    display: inline-block;

    @media screen and (max-width: 768px){
        font-size: 26px;
    }
`

export const MiddleWrapperSpanText2 = styled.span`
    font-size: 30px;
    padding-bottom: 100px;
    line-height: 1.3;
    display: inline-block;

    @media screen and (max-width: 768px){
        font-size: 26px;
    }
`

export const MiddleWrapperText1 = styled.p`
    font-size: 28px;
    font-weight: 600;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }
`
export const MiddleWrapperText2 = styled.p`
    font-size: 28px;
    font-weight: 600;
    color: #248022;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: left;
    margin-bottom: 44px;
    padding-left: 60px;

    @media screen and (max-width: 768px){
        padding-left: 20px;
    }

`;

export const Button = styled(LinkR)`
    border-radius: 5px;
    padding: 9px;
    font-weight: 500;
    width: 100%;
    font-size: 26px;
    background-color: #33BB30;
    color: white;
    white-space: nowrap;
    outline: none;
    border:none;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;
    text-decoration: none;


    @media screen and (max-width: 768px){
        /* padding: px; */
        font-size: 22px;

    }
    /* &:hover{
        transition: all 0.2s ease-in-out;
        background: #C4BDAC;
    }    */
`;

export const ItemsWrap = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    padding-bottom: 40px;
    grid-gap: 10px;
    padding-left: 0;
    justify-content: space-around;
    width: auto;

    @media screen and (max-width: 768px){
        grid-gap: 4px;
        /* padding-left: 22px; */
        /* grid-template-columns: 1fr 1fr 1fr; */
    }

    @media screen and (max-width: 480px){
        grid-gap: 4px;
        padding-left: 0px;
        /* grid-template-columns: 1fr 1fr 1fr; */
    }
`;

export const ItemWrapText = styled.p`
    font-size: 22px;
    padding-top: 80px;
    justify-content: center;
    /* padding-left: 70px; */
    
    @media screen and (max-width: 768px){
        padding-top: 50px;
        padding-left: 15px;
        font-size: 18px;
    }

    @media screen and (max-width: 768px){
        padding-top: 50px;
        padding-left: 15px;
        font-size: 15px;
    }
`

export const ItemWrapText1 = styled.p`
    font-size: 22px;
    padding-top: 80px;
    justify-content: center;
    width: 215px;
    /* padding-left: 80px; */
    
    @media screen and (max-width: 768px){
        padding-top: 50px;
        font-size: 18px;
        padding-left: 10px;
        width: 115px;

    }

    @media screen and (max-width: 480px){
        padding-top: 50px;
        font-size: 15px;
        width: 85px;

    }

    @media screen and (max-width: 280px){
        padding-top: 50px;
        font-size: 15px;
        padding-left: 0;
        width: 70px;

    }
`;
export const ItemWrapTextImg = styled.div`
    background: url(${Trash});
    width: 45px;
    height: 45px;
    background: black;

`
export const ItemWrapImg = styled.img`
    /* background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:100%; */
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 280px;
    width: 280px;
    justify-content: center;

    @media screen and (max-width: 768px){
        height: 150px;
        width: 150px;

    }

    @media screen and (max-width: 320px){
        height: 130px;
        width: 130px;

    }
`

export const ButtonWrap1 = styled.div`
    padding-top: 20px;
    display: flex;
    justify-content: left;
    margin-bottom: 40px;
    padding-left: 60px;

    @media screen and (max-width: 768px){
        padding-left: 20px;
    }


`;

export const Button1 = styled.button`
    font-weight: 600;
    font-size: 20px;
    background-color: white;
    color: #248022;
    white-space: nowrap;
    outline: none;
    border:none;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;


    @media screen and (max-width: 768px){
        font-size: 16px;

    }

    /* &:hover{
        transition: all 0.2s ease-in-out;
        color: grey;
    }    */
`;

