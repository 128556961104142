import styled from "styled-components";
import Img from '../../images/tick.webp'
import { Link as LinkR } from 'react-router-dom'
import { motion } from 'framer-motion';


export const Container = styled(motion.div)`
    /* min-height: 692px; */
    height: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 0;
    overflow: hidden;
    background: white;

    @media screen and (max-width:768px){
        top: 0;
    }
`;

export const TopWrapper = styled.div`
    height: 240px;
    width: 100%;
    /* padding: 0 20px; */
    padding-top: 50px;
    justify-content: center;
    display: block;
    position: relative;
`

export const TickImg = styled.div`
    background: url(${Img});
    padding-bottom: 30px;
    height: 150px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
`;


export const MiddleWrapper = styled.div`
    position: relative; 
    justify-content: center;
    padding-left: 60px;
    padding-bottom: 40px;
    text-align: center;

    @media screen and (max-width: 768px){
        padding-left: 10px;
        padding-right: 10px;
    }

`;

export const MiddleWrapperTextBold = styled.p`
    font-size: 30px;
    padding-bottom: 20px;
    font-weight: bold;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }
`

export const MiddleWrapperText = styled.p`
    font-size: 30px;
    padding-bottom: 60px;
    line-height: 1.3;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }
`

export const MiddleWrapperText1 = styled.p`
    font-size: 30px;
    padding-bottom: 20px;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }
`

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    /* padding-bottom: 20px; */
    padding-top: 30px;

    @media screen and (max-width: 768px){
        justify-content: center;
    }
`;

export const Button = styled.a`
    text-decoration:none;
    border-radius: 5px;
    padding: 11px 22px;
    font-weight: 600;
    font-size: 24px;
    background-color: #33BB30;
    color: white;
    white-space: nowrap;
    outline: none;
    /* border: 1px black solid; */
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;


    @media screen and (max-width: 768px){
        padding: 10px 12px;
        font-size: 22px;
    }

    /* &:hover{
        transition: all 0.2s ease-in-out;
        background: #C4BDAC;
    }    */
`;

export const BackLink = styled(LinkR)`
    color: #33BB30;
    font-size: 22px;
    text-decoration: none;
    font-weight: 600;
    /* @media screen and (max-width: 768px){
        text-align: center;
    } */
`