import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import { motion } from 'framer-motion';


export const Container = styled(motion.div)`
    /* min-height: 692px; */
    height: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 0;
    background: white;
    padding-top: 25px;

    @media screen and (max-width: 768px){
        top: 0;
    }
`;

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 400px){
        height: 100%;
    }
`;

// export const Icon = styled(LinkR)`
//     margin-left: 32px;
//     margin-top: 32px;
//     /* padding-left: 1000px; */
//     text-decoration: none;
//     color: black;
//     font-weight: 700;
//     font-size: 32px;
//     float: right;
//     @media screen and (max-width: 480px){
//         margin-left: 16px;
//         margin-top: 8px;
//         padding-left: 0;
//     }
// `;

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;

    @media screen and (max-width: 480px){
        padding: 0px;
    }
`;

export const Form = styled.form`
    background: white;
    max-width: 750px;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 20px 32px;
    font-weight: 600;

    /* border-radius: 9px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9); */
    @media screen and (max-width: 400px){
        padding: 2px 32px;
    }
`;

export const FormH1 = styled.h1`
    margin-bottom: 25px;
    color: black;
    font-size: 24px;
    text-align: center;
    /* text-decoration: underline; */
    /* border-bottom: 1px solid black; */
`;

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 16px;
    /* font-weight: bold; */
    /* padding-bottom: 10px; */
    /* color: #fff; */
    /* font-weight: bold; */

`;

export const FormInput = styled.input`
    padding: 15px 15px;
    margin-top: 7px;
    margin-bottom: 22px;
    border: 1px solid #8A8A8A;
    border-radius: 6px;
    font-weight: 500;
    font-size: 20px;

    @media screen and (max-width:400px){
        padding: 5px 10px;
        font-size: 18px;
    }
`;

// export const FormInput1 = styled.input`
//     font-weight: bold;
//     padding: 16px 16px;
//     margin-bottom: 32px;
//     border: 1px solid black;
//     border-radius: 4px;
//     height: 20px;

// `;

export const FormButton = styled.button`
    background: #33BB30;
    padding: 20px 10px;
    /* border: none; */
    /* border: 1px solid black; */
    border-radius: 3px;
    color: white;
    font-size: 26px;
    cursor: pointer;
    border: none;
    box-shadow: 1px 2px 2px #888888;

    @media screen and (max-width:480px){
        padding: 12px 8px;
        font-size: 22px;
    }

`;

export const Text = styled.span`
    /* text-align: center; */
    /* margin-top: 24px; */
    color: black;
    font-size: 14px;
    padding-bottom: 20px;
    font-weight: 900;

`;

export const FormInputItem = styled.textarea`
    padding: 16px 16px;
    margin-bottom: 32px;
    /* border: 1px solid black; */
    border-radius: 4px;

`;

export const MiddleWrap = styled.div`
    position: relative; 
    justify-content: flex-start;
    padding-left: 60px;
    padding-bottom: 40px;
    top: 80px;

    @media screen and (max-width: 768px){
        padding-left: 20px;
        padding-right: 20px;

    }

`;
export const MiddleWrapText = styled.p`
    font-size: 30px;
    padding-bottom: 100px;
`;


export const MiddleWrapSpanText = styled(LinkR)`
    font-size: 30px;
    /* padding-bottom: 100px; */
    display: inline-block;
`;