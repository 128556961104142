import React,  {useEffect, useContext} from 'react'
import CartSection from '../components/CartSection'
import Context from '../store/Context'

const CartPage = () => {

    const context = useContext(Context);

    useEffect(() => {
        // console.log(context);
    }, [context]);

    return (
        <CartSection carts = {context.carts} addProductToCart = {context.addProductToCart} removeProductFromCart = {context.removeProductFromCart} clearCart = {context.clearCart} />
    )
}

export default CartPage
