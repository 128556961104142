import React from 'react'
import { InfoContainer, InfoContent, InfoTitleH1, InfoP } from './InfoSectionElements'

const InfoSection = () => {
    return (
        <>
            <InfoContainer initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0.4, duration: 1.5}}>
                {/* <InfoImage/> */}
                <InfoContent>
                        <InfoTitleH1>PEACEFUL TABLETOP</InfoTitleH1>
                        <InfoP>
                            Kokedama 苔玉, which directly translates to moss ball, is a Japanese gardening art 
                            that draws inspiration from nearai bonsai and kusamono planting styles.
                        </InfoP>
                </InfoContent>
            </InfoContainer>
        </>
    )
}

export default InfoSection
