import React, {useEffect, useContext} from 'react'
import OrderForm from '../components/OrderFormSection'
import Context from '../store/Context'


const CheckOutPage = () => {

    const context = useContext(Context);

    useEffect(() => {
        // console.log(context);
    }, [context]);


    return (
        <OrderForm carts = {context.carts} addProductToCart = {context.addProductToCart}/>
    )
}

export default CheckOutPage
