import React from 'react'
import { SidebarContainer, Icon, SidebarWrapper, SidebarMenu, CloseIcon, SidebarLink, SidebarLink1 } from './SidebarElements'


const Sidebar = ({ isOpen, toggle }) => {
    return (
        <>
            <SidebarContainer isOpen={isOpen} onClick={toggle}>
                <Icon onClick={toggle}>
                    <CloseIcon />
                </Icon>
                <SidebarWrapper>
                    <SidebarMenu>
                        {/* <SidebarLink1 to="/cart">
                            Cart 
                            &nbsp;&nbsp;
                            <CartImg src = {Img}/>
                        </SidebarLink1> */}
                        <SidebarLink1 to= "/about" onClick={toggle}>
                            About
                        </SidebarLink1>
                        <SidebarLink to="/#shop" smooth = "true" duration = {500} spy = "true"
                                exact = 'true' offset = {-80} onClick={toggle}>
                            Shop
                        </SidebarLink>
                        <SidebarLink1 to= "/faq" onClick={toggle}>
                            FAQ
                        </SidebarLink1>
                    </SidebarMenu>
                </SidebarWrapper>
            </SidebarContainer>
        </>
    )
}

export default Sidebar
