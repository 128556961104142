import React, {useEffect} from 'react'
import Faq from 'react-faq-component';
import { FAQContainer, FAQText, Button, ButtonWrap, Button1, BackLink } from './FAQSectionElements';
import {FaInstagram} from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'
import { motion } from 'framer-motion';

const FAQSection = () => {
    
    useEffect(() => {
        window.scroll(0,0);
    });

    const toggleHome = () => {
        scroll.scrollToTop()
    };

    const data = {
        title: "Kokedama",
        rows: [
            {
                title: "How to care for Kokedamas?",
                content: "Keep them indoors in a well-lit area, out of direct sunlight (morning sun is fine!).To water, soak moss ball in a bowl of water 1-2 times a week for 10-25 minutes. Allow excess water to drain before placing it back on the plate. If moss is dry to the touch, lightly mist the surface with a spray bottle."
            },
            {
                title: "How big are the kokedamas?",
                content: "All dimensions are included in the individual product pages."
            },
            {
                title: "Will the kokedamas grow bigger?",
                content: "Since these are tabletop plants, the kokedamas will stay more or less the same size as when purchased."
            },
            {
                title: "Can it be grown indoors?",
                content: "Unless otherwise stated, all products at Peaceful tabletop are suitable to be grown in indoor environments. Keep them indoors in a well-lit area, out of direct sunlight (morning sun is fine!)"
            }]
    }

    const styles = {
        // bgColor: 'white',
        titleTextColor: "black",
        titleTextSize: "30px",
        rowTitleColor: "#6C6C6C",
        rowTitleTextSize: '20px',
        rowContentTextSize: '18px',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        
    };
    
    const data1 = {
        title: "Order",
        rows: [
            {
                title: "How to order?",
                content: "Please place orders through our website. After you submit the order form, our customer service personnel will be in contact with you via SMS to confirm the order and provide you with checkout and delivery details."
            },
            {
                title: "How long will it take to be delivered?",
                content: "Under normal circumstances, orders placed on weekdays will be delivered on the following Monday. Orders placed on weekends will be delivered on the Monday after next. This is subjected to change and the exact date and time will be made known to you when we contact you."
            },
            {
                title: "Can I make a rushed order?",
                content: "Absolutely, please DM us on Instagram (@peaceful.tabletop) and we will try our very best to accommodate. Expedited delivery will incur a $10 surcharge."
            }]
    }

    return (
        <>
            <FAQContainer 
                initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0.4, duration: 1.5}}>
            <Faq
                data={data}
                styles={styles}
            />
            <br/><br/><br/>
            <Faq 
                data = {data1} 
                style = {styles}/>
            <FAQText>Didn't find what you were looking for?</FAQText>
            <ButtonWrap>
                <motion.div whileTap = {{scale: 0.8}}>
                <Button href='//www.instagram.com/peaceful.tabletop/' target="_blank"><FaInstagram/> &nbsp; Drop us a DM </Button>
                </motion.div>
            </ButtonWrap>
            <ButtonWrap>
                <motion.div whileTap = {{scale: 0.8}}>
                    <Button1 smooth = "true" to = "/#shop">View the Collection</Button1>
                </motion.div>
            </ButtonWrap>
            <ButtonWrap>
                <motion.div whileTap = {{scale: 0.8}}>
                    <BackLink to = "/" onClick = {toggleHome}>Back to Home</BackLink>
                </motion.div>
            </ButtonWrap>
            </FAQContainer>
        </>
    )
}

export default FAQSection
