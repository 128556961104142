import styled from "styled-components";
import { motion } from "framer-motion";
import Img from '../../images/bg.webp';
import {Link as LinkS} from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'


export const LandingContainer = styled(motion.div)`
    /* padding-top: 100px; */
    background-image: url(${Img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 900px;
    position: relative;
    z-index: 1;
    border: none;
    margin: 0;

    @media screen and (max-width: 768px){
        height: 600px;
    }


`;

// export const LandingBg = styled(motion.img)`
//     z-index: -1;
//     width: 1000px;
//     /* margin-top: -10px; */
//     height: 800px;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     margin: 0;


//     @media screen and (max-width: 768px){
//         height: 500px;
//     }
// `;

export const LandingNav = styled(motion.div)`
    height: 100px;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    top: 0;
    z-index: 10;
    position: absolute;
    top: 800px;


    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }

    @media screen and (max-width: 768px){
        top: 520px;
    }

    @media screen and (max-width: 480px){
        top: 450px;
    }
`;

export const LandingNavbarContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    height: 100px;
    z-index: 1;
    width: 100%;
    max-width: 100%;


`;

export const LandingNavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    /* margin-right: -22px; */
    padding: 0;
    right: 0;
    max-width: 100%;


`;

export const LandingNavItem = styled.li`
    height: 100px;
   
`;

export const LandingNavLinks = styled(LinkS)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 80px;
    height: 100%;
    font-size: 30px;
    cursor: pointer;

      @media screen and (max-width: 768px){
        font-size: 20px;
        padding: 0 40px;

    }

    @media screen and (max-width: 480px){
        font-size: 20px;
        padding: 0 20px;

    }

`;

export const LandingNavLinks1 = styled(LinkR)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 80px;
    height: 100%;
    font-size: 30px;
    cursor: pointer;

      @media screen and (max-width: 768px){
        font-size: 20px;
        padding: 0 40px;

    }

    @media screen and (max-width: 480px){
        font-size: 20px;
        padding: 0 20px;

    }

/* 
    &:hover {
        color: grey;
        transition: 0.2s ease-in-out;
    } */
`;


