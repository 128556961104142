import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
// import Img from "react-webp-image";
import { motion } from 'framer-motion';

export const ProductCard = styled(LinkR)`
    background: transparent;
    display: flex;
    color:black;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 30px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    padding-bottom: 30px;



    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color:black;
    }


    @media screen and (max-width: 768px){

        min-height: 290px;
        padding: 0px;
        min-height: 0;
        padding-left: 10px;

    }


    @media screen and (max-width: 540px){

        min-height: 290px;
        padding: 0px;
        min-height: 0;
        padding-left: 6px;

    }

    @media screen and (max-width: 480px){

        padding: 0px;
        min-height: 0;
        padding-left: 6px;
        min-height: 260px;

    }

    @media screen and (max-width: 300px){
        padding: 0px;
        min-height: 0;
        padding-left: 6px;
        min-height: 240px;
    }   
`;

export const ProductImgContainer = styled(motion.div)`
    width: inherit;
    background-position: left center;

`;

export const ProductImg = styled.img`
    height: 230px;
    width: inherit;
    background-size: contain;
    border-radius: 8px;

    @media screen and (max-width: 768px){
        height: 215px;
        width: 215px;
        object-fit: contain;

    }

    @media screen and (max-width: 540px){
        height: 200px;
        width: 200px;
        object-fit: contain;

    }

    @media screen and (max-width: 480px){
        height: 190px;
        width: 180px;
        object-fit: contain;
        border-radius: 8px;

    }

    
    @media screen and (max-width: 360px){
        height: 190px;
        width: 150px;
        object-fit: contain;
        border-radius: 8px;
    }

    @media screen and (max-width: 280px){
        height: 170px;
        width: 130px;
        object-fit: contain;
        border-radius: 8px;
    }
`;

// export const ProductH1 = styled.h1`
//     font-size: 2.5rem;
//     color: black;
//     margin-bottom: 64px;

//     @media screen and (max-width: 480px){
//         font-size: 2rem;
//     }
// `;

export const ProductH2 = styled.h1`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0px;
    padding-left: 8px;

    @media screen and (max-width: 540px){
        font-size: 15px;
        padding-left: 5px;
    }

    @media screen and (max-width: 280px){
        font-size: 12px;
        padding-left: 5px;
    }
`;

export const ProductH2Wrapper = styled.div`
    height: 60px;
    @media screen and (max-width: 540px){
        height: 38px;
    }
`;

export const ProductPriceDiv = styled.div`
    display:inline;
    padding-left: 8px;

    @media screen and (max-width: 540px){
        padding-left: 5px;
    }
`;

export const ProductPrice = styled.h3`
    font-size: 24px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-decoration: none;
    display: inline;
    font-weight: 900;

    @media screen and (max-width: 540px){
        font-size: 15px;
    }
`;

export const ProductPrice1 = styled.span`
    font-size: 19px;
    text-decoration: none;
    font-weight: 600;

    /* font-weight: 600; */
`;

export const ProductP = styled.h2`
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    color: #808080;
    font-weight: bolder;
    padding-left: 8px;


    /* &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color:black;
    } */
    @media screen and (max-width: 540px){
        font-size: 8px;
        padding-left: 5px;
    }
`;


export const ProductWordsDiv = styled.div`
    height: 45px;
    @media screen and (max-width:768px){
        height: 22px;
    }
`
export const ProductWords = styled.p`
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    color:black;
    padding-left: 8px;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color:black;
    }

    @media screen and (max-width: 540px){
        font-size: 8px;
        padding-left: 5px;

    }

    @media screen and (max-width: 280px){
        font-size: 6px;
        padding-left: 3px;

    }

`;

export const ProductBlank = styled.span`
    height: 20px;
    @media screen and (max-width: 768px){
        height: 20px;
    }
`;

export const ProductTypeDiv = styled.div`
    height: 20px;

    @media screen and (max-width: 768px){
        height: 11px;
    }
` 

