import React from 'react'
import { FooterContainer, SocialIcon, SocialIconImg, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinksItems, FooterLinkTitle, FooterLink, FooterLink1, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIconLink, SocialIconSpan, } from './FooterSectionElements'
import Img1 from '../../images/Instagram.webp'
import Img2 from '../../images/carousell.webp'
import Img3 from '../../images/Facebook.webp'


const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinksItems>
                            <FooterLinkTitle>About Us</FooterLinkTitle>
                                {/* A tag for off site stuff */}
                                <FooterLink1 to ='/about'>
                                    About
                                </FooterLink1>
                                <FooterLink to = '/#shop'
                                smooth = "true" duration = {500} spy = "true"
                                exact = 'true' offset = {-80}>
                                    Plants
                                </FooterLink>
                                <FooterLink1 to = '/faq'>
                                    Info
                                </FooterLink1>
                        </FooterLinksItems>
                        <FooterLinksItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <SocialIcon>
                            <SocialIconLink href='https://www.instagram.com/peaceful.tabletop/' target="_blank" aria-label = "Facebook">
                                <SocialIconImg src = {Img1}></SocialIconImg>
                                <SocialIconSpan> &nbsp;&nbsp; @peaceful.tabletop</SocialIconSpan>
                            </SocialIconLink>
                            </SocialIcon>
                            <SocialIcon>
                            <SocialIconLink href='https://www.carousell.sg/peaceful.tabletop/' target="_blank" aria-label = "Facebook">
                                <SocialIconImg src = {Img2}></SocialIconImg>
                                <SocialIconSpan> &nbsp;&nbsp; @peaceful.tabletop</SocialIconSpan>
                            </SocialIconLink>   
                            </SocialIcon>
                            <SocialIcon>
                            <SocialIconLink href='https://www.facebook.com' target="_blank" aria-label = "Facebook">
                                <SocialIconImg src = {Img3}></SocialIconImg>
                                <SocialIconSpan> &nbsp;&nbsp; Peaceful  Tabletop</SocialIconSpan>
                            </SocialIconLink>
                            </SocialIcon>
                        </FooterLinksItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo>Peaceful TableTop</SocialLogo>
                        <WebsiteRights>© {new Date().getFullYear()} All Rights Reserved.</WebsiteRights>
                        {/* <SocialIcons>
                            <SocialIconLink1 href='https://www.facebook.com' target="_blank" aria-label = "Facebook">
                                <FaFacebook/>
                            </SocialIconLink1>
                            <SocialIconLink2 href='https://www.instagram.com/peaceful.tabletop/' target="_blank" aria-label = "Instagram">
                                <FaInstagram/>
                            </SocialIconLink2>
                            <SocialIconLink3 href='https://www.carousell.sg/peaceful.tabletop/' target="_blank" aria-label = "Youtube">
                                <FaYoutube/>
                            </SocialIconLink3>
                        </SocialIcons> */}
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
