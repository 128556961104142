import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar';
import { Overlay } from './GeneralStyles'
import AboutSection from '../components/AboutSection';

const AboutPage = () => {

    const [isOpen, setIsOpen] = useState(false);
    
    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
                { isOpen && <Overlay /> }
            <Navbar toggle={toggle} />
            <AboutSection/>
        </>
    )
}

export default AboutPage
