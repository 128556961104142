import React from 'react'
import PurchasedPageSection from '../components/PurchasePageSection'


const PurchasedPage = () => {


    return (
        <PurchasedPageSection/>
    )
}

export default PurchasedPage