import styled from "styled-components";
import { motion } from "framer-motion";
import Img from '../../images/AboutPage.webp'
import { Link as LinkR } from 'react-router-dom'
import { HashLink as LinkH } from 'react-router-hash-link';


export const AboutContainer = styled(motion.div)`
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 900px; */
    position: relative;
    z-index: 1;
    border: none;
    margin: 0;
    padding: 40px 60px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px){
        padding: 15px 30px;

    }
`;

export const AboutWrapper = styled.div`
    top: 50px;
    /* padding: 0 20px; */
    padding-bottom: 20px;
    text-align: left;
`

export const AboutTextHeading = styled.p`
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 30px;
`

export const AboutText = styled.p`
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 40px;
    font-weight: 500;
    line-height: 1.5;

`

export const AboutTextGreen = styled.span`
    background-color: #248022;
    color: white;
`

export const ImgWrapper = styled.div`
    background: url(${Img});
    margin-bottom: 30px;
    height: 500px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    /* padding-bottom: 60px; */

    @media screen and (max-width: 768px){
        height: 400px;
        background-position: center;

    }
`

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: left;
    padding-bottom: 20px;

    @media screen and (max-width: 768px){
        justify-content: center;
    }
`;

export const Button = styled(LinkH)`
    text-decoration:none;
    border-radius: 5px;
    padding: 11px 22px;
    font-weight: 600;
    font-size: 24px;
    background-color: #33BB30;
    color: white;
    white-space: nowrap;
    outline: none;
    /* border: 1px black solid; */
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;


    @media screen and (max-width: 768px){
        font-size: 20px;
        padding: 11px 18px;

    }

    /* &:hover{
        transition: all 0.2s ease-in-out;
        background: #C4BDAC;
    }    */
`;

export const BackLink = styled(LinkR)`
    color: #33BB30;
    font-size: 22px;
    text-decoration: none;
    font-weight: 600;

`
