import React from 'react'
import { LandingContainer, LandingNav, LandingNavbarContainer, LandingNavMenu, LandingNavItem, LandingNavLinks, LandingNavLinks1  } from './LandingSectionElements'
// import {animateScroll as scroll} from 'react-scroll'
import { motion } from 'framer-motion'


const LandingSection = () => {
    return (
        <>
            <LandingContainer initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0.4, duration: 1.5}}>
                {/* <LandingBg/> */}
                <LandingNav>
                    <LandingNavbarContainer>
                    <LandingNavMenu>
                        <motion.div whileTap = {{scale: 0.8}}>
                        <LandingNavItem>
                            <LandingNavLinks1 to = "/about" >
                                About
                            </LandingNavLinks1>
                        </LandingNavItem>
                        </motion.div>
                        <motion.div whileTap = {{scale: 0.8}}>
                        <LandingNavItem>
                            <LandingNavLinks to = "shop" smooth = "true" duration = {500} spy = {true}
                                exact = 'true' offset = {-80}>
                                    Shop
                            </LandingNavLinks>
                        </LandingNavItem>
                        </motion.div>
                        <motion.div whileTap = {{scale: 0.8}}>
                        <LandingNavItem>
                            <LandingNavLinks1 to = "/faq">
                                    FAQ
                            </LandingNavLinks1>
                        </LandingNavItem>
                        </motion.div>
                    </LandingNavMenu>
                    </LandingNavbarContainer>
                </LandingNav>
            </LandingContainer>
        </>
    )
}

export default LandingSection
