import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
// import {Link as LinkS} from 'react-scroll'
import Img from '../../images/logonew.png';
import { motion } from 'framer-motion';
import Cart from '../../images/cart.webp'


export const Nav = styled(motion.nav)`
    /* background: transparent; */
    /* border-bottom: ${({scrollNav}) => (scrollNav ? '2px solid black' : 'none')}; */
    /* background: ${({scrollNav}) => (scrollNav ? '#f4eee1': 'transparent')}; */
    background: white;
    /* display: ${({scrollNav}) => (scrollNav ? 'flex' : 'none')}; */
    height: 100px;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    /* justify-content: space-between; */
    height: 90px;
    z-index: 1;
    width: 100%;
    padding: 0 50px;
    max-width: 100%;

    @media screen and (max-width: 768px){
        padding: 0 30px;
    }

    @media screen and (max-width: 480px){
        padding: 0 10px;
    }

    @media screen and (max-width: 280px){
        /* padding: 0 10px; */  
        padding-left: 1px;
        padding-right: 5px;
    }
`;

export const NavLogo = styled(LinkR)`
    background: url(${Img});
    margin-top: 13px;
    width: 80px;
    height: 87px;
    background-size: 100%;
    background-repeat: no-repeat;


    @media screen and (max-width: 768px){
        width: 70px;
    }
    @media screen and (max-width:460px){
        width: 60px;
    }
    @media screen and (max-width: 280px){
        width: 55px;
    }
`;

export const MobileIcon = styled.div`
    padding-top: 3px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 2rem;
    cursor: pointer;
    /* float: right; */
    justify-content: end;
`;

export const CartImage = styled(LinkR)`
    background: url(${Cart});
    /* margin-top: 23px; */
    position: absolute;
    padding-left: 20px;
    width: 32px;
    height: 32px;
    top: 28px;
    right: 90px;
    background-size: 100%;
    background-position: 60% 90%;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
    
`;

export const NavMenu = styled.div`
    float: right;
    width: 50%;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    left: 0;
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    justify-content: space-around;
    /* margin-right: -22px; */
    
    @media screen and (max-width: 480px){
        padding-left: 3px;
    }

`;

export const NavItem = styled.span`
    height: 80px;   
`;

export const NavTitle = styled.div`

    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 50px;
    height: 100%;
    font-size: 30px;
    text-shadow: 2px 2px 4px grey;
    
    @media screen and (max-width: 900px){
        font-size: 28px;
        padding: 0 25px;
        padding-right: 10px;
        height: 95px;
    }

    @media screen and (max-width: 768px){
        font-size: 18px;
        padding: 0 25px;
        padding-right: 10px;
        height: 85px;
    }

    @media screen and (max-width: 540px){
        font-size: 14px;
        padding: 0 25px;
        padding-right: 10px;
        height: 85px;
    }

    @media screen and (max-width: 480px){
        font-size: 12px;
        height: 85px;
        padding-left: 15px;
        padding-right: 12px;
    }

    @media screen and (max-width: 320px){
        font-size: 11px;
        padding-left: 5px;
        padding-right: 2px;
        height: 85px;
    }
    @media screen and (max-width: 280px){
        font-size: 9px;
        height: 80px;

    }

`;

export const NavLinks = styled.span`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 36px;
    height: 100%;
    font-size: 30px;
`;

