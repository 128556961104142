import React, { useContext, useEffect, useState } from 'react'
import { PlantContainer, PlantWrapper, PlantRow, TextWrapper, TopLine, Image, TopLine2, Subtitle, Subtitle1, SubtitleHeading, Heading, Heading1, ButtonWrap, Button, ImgWrap, ButtonWrap2, Button2, ButtonDisabled, InlineImage, InlineImage1 } from './PlantSectionElements'
import { useParams } from 'react-router-dom'
import Context from '../../store/Context'
import { motion } from 'framer-motion';
// import {wrap} from '@popmotion/popcorn'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavTitle, CartImage } from '../Navbar/NavbarElements'
import { animateScroll as scroll } from 'react-scroll'
import { FaBars } from 'react-icons/fa'
import Sidebar from '../Sidebar'
import { Overlay } from '../../pages/GeneralStyles'
import Truck from '../../images/truck.webp'
import Plate from '../../images/plate.webp'
import { BsArrowLeft } from "react-icons/bs";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import Carousel from 'react-elastic-carousel';
import './styles.css';

const PlantSection = ({ addProductToCart, ...props }) => {
    const context = useContext(Context);
    const { id } = useParams();
    const Plant = context.products[id];
    // const Images = Plant.imageData;
    // console.log(Images);

    //Navbar 
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        }
        else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop()
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    //Animate cart button when added
    const [shakeCart, setShakeCart] = useState(false);


    //Button Stuff
    const text = "Add To Cart";
    const [buttonText, setButtonText] = useState(text);
    useEffect(() => {
        const timer = setTimeout(() => {
            setButtonText(text);
            setShakeCart(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, [buttonText])


    //image gallery
    // const carouselRef = React.useRef(null);
    // const onNextStart = (currentItem, nextItem) => {
    // if (currentItem.index === nextItem.index) {
    //     // we hit the last item, go to first item
    //     carouselRef.current.goTo(0);
    // }
    // };
    // const onPrevStart = (currentItem, nextItem) => {
    // if (currentItem.index === nextItem.index) {
    //     // we hit the first item, go to last item
    //     carouselRef.current.goTo(Images.length);
    // }
    // };
        return (
            <>
                <Sidebar isOpen={isOpen} toggle={toggle} />
                {isOpen && <Overlay />}
                <Nav scrollNav={scrollNav}
                    initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4, duration: 1.2 }}
                    >
                    <NavbarContainer>
                        <NavLogo to="/" onClick={toggleHome} />
                        <NavTitle>P E A C E F U L<br />T A B L E T O P</NavTitle>
                        <NavMenu>
                            <motion.div animate={{ opacity: shakeCart ? [1, 0.4, 1, 0.4, 1] : 1 }} transition={{ duration: 1.2 }}>
                                <CartImage to="/cart" />
                            </motion.div>
                            <MobileIcon onClick={toggle}>
                                <FaBars />
                            </MobileIcon>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
                <PlantContainer
                    initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4, duration: 1.5 }}
                    >
                    <PlantWrapper>
                        <PlantRow>
                            <ImgWrap>
                                <Image src = {Plant.imageWebpURL} alt = "hehehe"/>
                                    {/* <Carousel autoPlay = {true}
                                        infiniteLoop = {true}
                                        showStatus	 = {false}
                                        showIndicators = {true}
                                        showThumbs = {false}
                                        transitionTime = '1500'
                                        showArrows = {false}
                                        swipeable = {true}
                                        emulateTouch = {true}
                                        >
                                        {Images.map((image, index) => {
                                            return(
                                                <Image key = {index} src = {image.image} alt = "hehehe"/>
                                            )
                                        })}
                                    </Carousel> */}
                                    {/* <Carousel 
                                      ref={carouselRef}
                                      onPrevStart={onPrevStart}
                                      onNextStart={onNextStart}
                                      disableArrowsOnEnd={false}
                                      enableAutoPlay = {true} 
                                      showArrows = {false}>
                                        {Images.map((image,index) => {
                                            return(
                                                <Image key = {index} src = {image.image} alt = "hehe"/>
                                            )
                                        } )}
                                    </Carousel> */}
                                {/* <AnimatePresence initial = {false} custom = {direction}>
                                    {Images.filter((_, iterator) => iterator === slideIndex)
                                            .map((slideItem) => (
                                        <Image
                                            key = {slide}
                                            src = {Images[slideIndex]}
                                            custom = {direction}
                                            initial = {{opacity:0}}
                                            animate = {{opacity:1}}
                                            exit = {{opacity:0}}
                                            transition = {{
                                                x:{type:'spring', stiffness:300, damping: 300}, 
                                                opacity:{duration:0.5}
                                            }}
                                            drag = "x"
                                            dragConstraints = {{left:0, right: 0}}    
                                            dragElastic = {1}
                                            // src={Plant.imageWebpURL} 
                                        alt="hehehe" />
                                            ))}
                                </AnimatePresence>
                                <CarouselButtonLeft 
                                    whileTap = {{scale: 0.9}}
                                    whileHover = {{scale:1.1}}
                                    onClick = {() => paginate(1)}><BsArrowLeft/></CarouselButtonLeft>
                                <CarouselButtonRight 
                                    whileTap = {{scale: 0.9}}
                                    whileHover = {{scale:1.1}}
                                    onClick = {() => paginate(1)}><BsArrowRight/></CarouselButtonRight> */}
                            </ImgWrap>
                            <TextWrapper>
                                {Plant.type !== "" ? (
                                    <TopLine2>{Plant.type}</TopLine2>
                                ) : (
                                    <TopLine>&nbsp;</TopLine>
                                )}
                                <TopLine>{Plant.title}</TopLine>
                                <Heading>${Plant.price}</Heading>

                                <ButtonWrap>
                                    <motion.div whileTap={{ scale: 0.8 }}>
                                        {Plant.inStock === "true" ? (<Button onClick={() => {
                                            setButtonText("Added to cart");
                                            addProductToCart({ ...Plant });
                                            setShakeCart(true);
                                        }}>
                                            {buttonText}
                                        </Button>) :
                                            <ButtonDisabled disabled={true}>Out Of Stock</ButtonDisabled>
                                        }
                                    </motion.div>
                                </ButtonWrap>
                                <Heading1><InlineImage1 src={Truck} /> &nbsp;&nbsp;Free doorstep delivery</Heading1>
                                <Heading1><InlineImage src={Plate} /> &nbsp;&nbsp;Free zen-style ceramic plate</Heading1>
                                <Subtitle>Dimensions: <br />{Plant.dimensions}</Subtitle>
                                <SubtitleHeading>Description</SubtitleHeading>
                                <hr />
                                <Subtitle1>{Plant.details}</Subtitle1>
                                <SubtitleHeading>Care</SubtitleHeading>
                                <hr />
                                <Subtitle1>
                                    Light: Keep indoors in a well-lit area, out of direct sunlight
                                    (morning sun is fine).
                                </Subtitle1>
                                <Subtitle1>
                                    To water, soak moss ball in a bowl of water 1-2 times a week for 10-25 minutes.
                                    Allow excess water to drain before placing it back on the plate.
                                </Subtitle1>
                                <Subtitle1>
                                    If moss is dry to the touch, mist the surface with a spray bottle.
                                </Subtitle1>
                            </TextWrapper>
                            <ButtonWrap2>
                                <motion.div whileTap={{ scale: 0.8 }}>
                                    <Button2 to="/#shop"> <BsArrowLeft />&nbsp;Back&nbsp;</Button2>
                                </motion.div>
                            </ButtonWrap2>
                        </PlantRow>
                    </PlantWrapper>
                </PlantContainer>
            </>
        )
    }

    export default PlantSection;
