import React, {useState, useEffect} from 'react'
import { FormLabel } from 'react-bootstrap'
import { NavbarContainer, NavLogo, NavTitle } from '../Navbar/NavbarElements'
import { Container, FormWrap, FormContent, Form, FormH1, FormInput, FormButton, MiddleWrap, MiddleWrapText, MiddleWrapSpanText } from './OrderFormSectionElements'
import axios from 'axios';
// import { motion } from 'framer-motion';

const OrderForm = ({carts}) => {

	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const dateTime = new Date().toLocaleString();

    var itemOrder = "";
    var totalPrice = 0;
    var messageOrder = "";
    // var quantity = 0;
    for(var i = 0; i < carts.length; i ++){
        totalPrice += (carts[i]['product']['price'] * carts[i]['quantity']);
        // quantity += carts[i]['quantity'];
    }
    carts.map(({product,quantity}) =>
        itemOrder += (product.title + ' - x ' + quantity + " ")
        );
    carts.map(({product,quantity}) =>
        messageOrder += (quantity + " " + product.title + "\n")
    );
    
    // console.log(itemOrder);
    // console.log(carts.length);
    var message = "Hey " + name + "! Neo from Peaceful Tabletop here :) We've received your order request for:\n\n" + messageOrder + " \nto be delivered to " + address + ".\n\nTo confirm your order, please PayNow $" + totalPrice + " to 9725 9370 (Peaceful Tabletop).\n\nCheers!" 
    // console.log(message);

    const text = "Submit";
    const [buttonText, setButtonText] = useState(text);
    useEffect(() => {
        const timer = setTimeout(() => {
            setButtonText(text);
        }, 2000);
        return () => clearTimeout(timer);
    }, [buttonText])


    // original version - neo
    const handleSubmit = (e) => {
        setButtonText("Please wait while we process the order");
		e.preventDefault();
		const objt = { dateTime, name, address, postalCode, phoneNo, itemOrder, message, promoCode };
		axios
			.post(
				'https://sheet.best/api/sheets/34c4c156-40c7-42ac-bcb0-7637cbec3dd4',
				objt
			)
			.then((response) => {
                window.location = "/purchased";
			});
	};

    //testing version - tim
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setButtonText("Please wait while we process the order");
	// 	const objt = { dateTime, name, address, postalCode, phoneNo, itemOrder, message, promoCode };
	// 	axios
	// 		.post(
	// 			'https://sheet.best/api/sheets/8f9c68bc-be24-4950-bf55-13a9010acc42',
	// 			objt
	// 		)
	// 		.then((response) => {
    //             window.location = "/purchased";
	// 		});
	// };



    useEffect(() => {
        window.scroll(0,0);
    });
    
    return (
        <>
            <NavbarContainer>
                    <NavLogo to = "/" />
                    <NavTitle>P E A C E F U L<br/>T A B L E T O P</NavTitle>
            </NavbarContainer>
            <Container initial={{opacity:0}} animate = {{opacity:1}} transition = {{delay:0, duration: 1.5}}>
            {carts.length === 0 ? (
                <MiddleWrap>
                    <MiddleWrapText>You have no items in your cart. Click <MiddleWrapSpanText to = "/">here</MiddleWrapSpanText> to go back.</MiddleWrapText>
                </MiddleWrap>
            ) : (
                
                <FormWrap>
                    <FormContent>
                        <Form>
                            <FormH1>Order Form</FormH1>
                            <FormLabel htmlFor = 'for'>Name:</FormLabel>
                            <FormInput type = "name" name = "name" onChange={(e) =>setName(e.target.value)} required/>
                            <FormLabel htmlFor = 'for'>Home Address:</FormLabel>
                            <FormInput type = "text" name = "address" onChange={(e) =>setAddress(e.target.value)} required/>
                            <FormLabel htmlFor = 'for'>Postal Code:</FormLabel>
                            <FormInput type = "text" name = "postalCode" onChange={(e) =>setPostalCode(e.target.value)}required/>
                            <FormLabel htmlFor = 'for'>Phone Number:</FormLabel>
                            <FormInput type = "text" name = "phoneNo"  onChange={(e) =>setPhoneNo(e.target.value)} required/>
                            <FormInput type = "hidden" name = "itemOrder" value = {itemOrder}/> 
                            <FormInput type = "hidden" name = "message" value = {message}/>
                            <FormLabel htmlFor = 'for'>Promo Code:</FormLabel>
                            <FormInput type = "text" name = "promoCode"  onChange={(e) =>setPromoCode(e.target.value)} placeholder="Optional" />
                            
                            {carts.length === 0 ? (
                                <FormButton disabled = {true}>Submit</FormButton>
                            ) : (
                                <FormButton type = "submit" onClick = {handleSubmit}>{buttonText}</FormButton>
                            )}
                        </Form>
                    </FormContent>
                </FormWrap>
                
            )}
            
            </Container>
        </>
    )
}

export default OrderForm