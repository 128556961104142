import './App.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import GlobalState from './store/GlobalState';
import PlantsPage from './pages/plants';
import CartPage from './pages/cart';
import CheckOutPage from './pages/checkOut';
import AboutPage from './pages/about';
import FAQPage from './pages/faq';
import PurchasedPage from './pages/purchased';


function App() {
  return (
    <Router>
      <GlobalState>
      <Switch>
        <Route path = "/" component = {Home} exact/>
        <Route path = "/plant/:id" component = {PlantsPage} exact/>
        <Route path = "/about" component = {AboutPage} exact/>
        <Route path = "/faq" component = {FAQPage} exact/>
        <Route path = "/cart" component = {CartPage} exact />
        <Route path = "/checkout" component = {CheckOutPage} exact/>
        <Route path = "/purchased" component = {PurchasedPage} exact/>
      </Switch>
      </GlobalState>
    </Router>
  );
}

export default App;
