import React, { useReducer } from 'react'
import Context from './Context'
import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_ALL_FROM_CART } from "./types";
import Reducer from './Reducer';
// import plantA from '../images/Aglaonema.jpg'
// import plantAF from '../images/AsparagusFern.png'
// import plantCNW from '../images/CaladiumNewWave.png'
// import plantCPJ from '../images/CalatheaPrincessJessie.png'
// import plantCMP from '../images/ChineseMoneyPlant.png'
// import plantDC from '../images/DracaenaCorn.png'
// import plantMA from '../images/MonsteraAdansonii.png'
// import plantOIC from '../images/OxalisIronCross.png'
// import plantPP from '../images/ParlorPalm.png'
// import plantPTP from '../images/PonytailPalm.png'
// import plantSSP from '../images/SilverSparklePilea.png'
// import plantSB from '../images/SnowroseBonsai.png'

import plantA from '../images/new/Aglaonema2.jpg'
import plantA2 from '../images/new/Aglaonema2.webp'
import plantAF from '../images/new/AsparagusFern2.jpg'
import plantAF2 from '../images/new/AsparagusFern2.webp'
import plantAMP from '../images/new/AfricanMask2.jpg'
import plantAMP2 from '../images/new/AfricanMaskPlant.webp'
import plantCNW from '../images/new/CaladiumNewWave2.jpg'
import plantCNW2 from '../images/new/CaladiumNewWave2.webp'
import plantCOW from '../images/new/CaladiumOrangeWave2.jpg'
import plantCOW2 from '../images/new/CaladiumOrangeWave2.webp'
import plantCSS from '../images/new/CaladiumStrawberryStar2.jpg'
import plantCSS2 from '../images/new/CaladiumStrawberryStar2.webp'
import plantCPJ from '../images/new/CalatheaPrincessJessie3.jpg'
import plantCPJ2 from '../images/new/CalatheaPrincessJessie3.webp'
// import plantCMP from '../images/new/ChineseMoneyPlant2.jpg'
// import plantCMP2 from '../images/new/ChineseMoneyPlant2.webp'
import plantDC from '../images/new/DracaenaCorn2.jpg'
import plantDC2 from '../images/new/DracaenaCorn2.webp'
import plantMA from '../images/new/MonsteraAdansonii2.jpg'
import plantMA2 from '../images/new/MonsteraAdansonii2.webp'
// import plantOIC from '../images/new/OxalisIronCross2.jpg'
// import plantOIC2 from '../images/new/OxalisIronCross2.webp'
import plantPP from '../images/new/ParlorPalm2.jpg'
import plantPP2 from '../images/new/ParlorPalm2.webp'
// import plantPTP from '../images/new/PonytailPalm2.jpg'
// import plantPTP2 from '../images/new/PonytailPalm2.webp'
// import plantSSP from '../images/new/SilverSparklePilea2.jpg'
// import plantSSP2 from '../images/new/SilverSparklePilea2.webp'
import plantSB from '../images/new/SnowroseBonsai2.jpg'
import plantSB2 from '../images/new/SnowroseBonsai2.webp'





export default function GlobalState(props) {

    const products = [
        {
            id:0, 
            title: "Snowrose Bonsai" ,
            imageJpgURL: plantSB,
            imageWebpURL: plantSB2,
            price: 68.0,
            price1: "68.0",
            details: "Also known as Japanese boxthorn or snow rose, this semi-evergreen shrub grows in sub-tropical woodlands and wet meadows in Japan, China, and southeast Asia. It boasts a beautiful foliage that responds well to severe pruning, making it a suitable entry-level bonsai for enthusiasts. Unlike conventional bonsais, they prefer indoor semi-shade environments — perfect for the tabletop.",
            dimensions: "W 10cm x H 30cm",
            type: 'TOP PRODUCT',
            inStock: "true"
        },
        {
            id:1, 
            title: "Monstera Adansonii",
            imageJpgURL: plantMA,
            imageWebpURL: plantMA2,
            price: 65.0,
            price1: "65.0",
            details: "Native to Central and South America, these hardy indoor plants are notoriously easy to care for.",
            dimensions: "W 10cm x H 25cm",
            type: 'TOP PRODUCT',
            inStock: "true"
        },
        // {
        //     id:2, 
        //     title: "Oxalis ‘Iron Cross’",
        //     imageJpgURL: plantOIC,
        //     imageWebpURL: plantOIC2,
        //     imageData: [{image:plantSB2}, {image:plantMA2}],
        //     price: 48.0,
        //     price1: "48.0",
        //     details: "A plant from the Oxalis family, the Oxalis Tetraphylla or ‘Iron Cross’ is characterized by the burgundy cross-like markings across the center of its leaves, which is composed of 4 heart-shaped leaflets.",
        //     dimensions: "W 10cm x H 25cm",
        //     type: 'HOT',
        //     inStock: "true"
        // },
        {
            id: 2, 
            title: "Caladium ‘Strawberry Star’",
            imageJpgURL: plantCSS,
            imageWebpURL: plantCSS2,
            price1: "65.0",
            price: 65.0,
            details: " Dotted with strawberry speckles across its white foliage, this Caladium draws inspiration from the beloved classic summer dessert, strawberries and cream.",
            dimensions: "W 10cm x H 25cm",
            type: '',
            inStock: "true"
        },
        {
            id:3, 
            title: "Asparagus Fern",
            imageJpgURL: plantAF,
            imageWebpURL: plantAF2,
            price: 58.0,
            price1: "58.0",
            details: "Used in terrariums and miniature landscapes, the asparagus fern is commonly used to simulate Moso Bamboo, the kind found in Arashiyama Bamboo Grove in Kyoto, Japan. Bring a bit of Zen to the tabletop!",
            dimensions: "W 10cm x H 25cm",
            type: 'HOT',
            inStock: "true"
        },
        {   
            id:4, 
            title: "Aglaonema Red",
            imageJpgURL: plantA,
            imageWebpURL: plantA2,
            price: 55.0,
            price1: "55.0",
            details: "Aglaonema Red (Chinese evergreen, Siam Aurora, Aglaonema Firecracker) is characterized by its bright red leaves that are decorated by green streaks that run along the sides. These indoor plants grow well in indirect light and are the perfect size for tabletops.",
            dimensions: "W 10cm x H 25cm",
            type: 'HOT',
            inStock: "true"
            
        },
        {
            id: 5, 
            title: "Caladium ‘Red Wave’",
            imageJpgURL: plantCNW,
            imageWebpURL: plantCNW2,
            price: 65.0,
            price1: "65.0",
            details: "Found in the South American tropics, the Caladium Red thrives in hot, humid weather. It’s loved for its large leaves and colourful foliage.",
            dimensions: "W 10cm x H 25cm",
            type: '',
            inStock: "true"
        },
        {
            id: 6, 
            title: "Caladium ‘Orange Wave’",
            imageJpgURL: plantCOW,
            imageWebpURL: plantCOW2,
            price1: "65.0",
            price: 65.0,
            details: "Found in the South American tropics, the Caladium thrives in hot, humid weather. It’s loved for its large leaves and colourful foliage. A variant of the Caladium ‘New Wave’.",
            dimensions: "W 10cm x H 25cm",
            type: '',
            inStock: "true"
        },
        {
            id: 7, 
            title: "African Mask Plant",
            imageJpgURL: plantAMP,
            imageWebpURL: plantAMP2,
            price: 55.0,
            price1: "55.0",
            details: "Not actually from Africa! The African Mask plant gets its name from its unique shape, which resembles hand-crafted ceremonial masks from the continent. It’s exotic and striking leaf shape makes it an eyecatcher in your workspaces.",
            dimensions: "W 10cm x H 30cm",
            type: '',
            inStock: "true"
        },
        {
            id: 8, 
            title: "Parlor Palm",
            imageJpgURL: plantPP,
            imageWebpURL: plantPP2,
            price: 48.0,
            price1: "48.0",
            details: "Native to Central America, the parlor palm is a popular indoor palm due to its low light requirement and light textured foliage.",
            dimensions: "W 10cm x H 25cm",
            type: '',
            inStock: "true"
        },
        // {
        //     id: 9, 
        //     title: "Silver Sparkle Pilea",
        //     imageJpgURL: plantSSP,
        //     imageWebpURL: plantSSP2,
        //     price: 48.0,
        //     price1: "48.0",
        //     details: "The cute silver sparkle pilea is characterised by its cascade of mini-leaves that looks like it has been sprinkled with silver “fairy-dust”. Magical!",
        //     dimensions: "W 10cm x H 25cm",
        //     type: '',
        //     inStock: "false"
        // },
        {
            id: 9, 
            title: "Calathea ‘Princess Jessie’ ",
            imageJpgURL: plantCPJ,
            imageWebpURL: plantCPJ2,
            price: 50.0,
            price1: "50.0",
            details: "The ‘Princess Jessie’ has naturally glossy medallion-like foliage and a deep purple colour. Most importantly, we think it looks somewhat like the Deadpool logo…",
            dimensions: "W 10cm x H 20cm",
            type: '',
            inStock: "true"
        },
        {
            id: 10, 
            title: "Dracaena ‘Corn’",
            imageJpgURL: plantDC,
            imageWebpURL: plantDC2,
            price: 35.0,
            price1: "35.0",
            details: "Looking for a truly low maintenance tabletop plant? The Dracaena 'Corn' plant (a.k.a Brazilian Lucky Wood) only needs a bit of water at the base of the plate every couple of days to see healthy growth.",
            dimensions: "W 10cm x H 15cm",
            type: 'Beginner-friendly',
            inStock: "true"
        }
        // {
        //     id: 12, 
        //     title: "Chinese Money Plant",
        //     imageJpgURL: plantCMP,
        //     imageWebpURL: plantCMP2,
        //     price: 44.0,
        //     price1: "44.0",
        //     details: "Originally from the southwestern Yunnan province of China, the Pilea (also known as Chinese money plant or pancake plant) is a cute little kokedama with round, waxy leaves. Be sure to rotate it once a week to keep it from growing lopsided.",
        //     dimensions: "W 10cm x H 20cm",
        //     type: '',
        //     inStock: "true"
        // }
        // {
        //     id: 14, 
        //     title: "Ponytail Palm",
        //     imageJpgURL: plantPTP,
        //     imageWebpURL: plantPTP2,
        //     price: 46.0,
        //     price1: "46.0",
        //     details: "This is a quirky one, we especially love its curly hair!",
        //     dimensions: "W 10cm x H 25cm",
        //     type: '',
        //     inStock: "false"
        // },
 

    ];

    const [state, dispatch] = useReducer(Reducer, {carts: [] })
    
    //add product to cart 
    const addProductToCart = (product) => {
        dispatch({
            type: ADD_TO_CART,
            payload: product,
        });
    };

    //remove product
    const removeProductFromCart = (productID) => {
        dispatch({
            type: REMOVE_FROM_CART,
            payload: productID,
        });
    };

    //clear cart
    const clearCart = () => {
        dispatch({
            type: CLEAR_ALL_FROM_CART,
        });
    };




    return (<Context.Provider
                value =  {{products: products, 
                addProductToCart: addProductToCart, 
                removeProductFromCart: removeProductFromCart, 
                clearCart: clearCart, 
                carts: state.carts,
                }}>
                    {props.children}
            </Context.Provider>
    );
}




