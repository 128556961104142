import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom'
import { HashLink as LinkH } from 'react-router-hash-link';
import { motion } from "framer-motion";

export const FAQContainer = styled(motion.div)`
    display: inline-block;
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 900px; */
    position: relative;
    z-index: 1;
    border: none;
    margin: 0;
    padding: 40px 60px;
    margin-bottom: 30px;

    @media screen and (max-width:768px){
        padding: 20px 30px;
    }
`;


export const FAQText = styled.p`
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 20px;
    font-weight: 500;
    padding-top: 60px;
    text-align: center;

    @media screen and (max-width:768px){
        font-size: 20px;

    }
`;

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: left;
    padding-bottom: 20px;
    justify-content: center;

    /* @media screen and (max-width: 768px){
        justify-content: center;
    } */
`;

export const Button = styled.a`
    text-decoration:none;
    border-radius: 5px;
    padding: 12px 19px;
    font-weight: 600;
    font-size: 24px;
    background-color: #6C6C6C;
    color: white;
    white-space: nowrap;
    outline: none;
    /* border: 1px black solid; */
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;
    min-width:100px;


    @media screen and (max-width: 768px){
        padding: 8px 21px;
        font-size: 20px;
    }
    /* &:hover{
        transition: all 0.2s ease-in-out;
        background: #C4BDAC;
    }    */
`;

export const Button1 = styled(LinkH)`
    text-decoration:none;
    border-radius: 5px;
    padding: 11px;
    font-weight: 600;
    font-size: 24px;
    background-color: #33BB30;
    color: white;
    white-space: nowrap;
    outline: none;
    /* border: 1px black solid; */
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2 all ease-in-out;


    @media screen and (max-width: 768px){
        padding: 8px 14px;
        font-size: 20px;
    }
    /* &:hover{
        transition: all 0.2s ease-in-out;
        background: #C4BDAC;
    }    */
`;

export const BackLink = styled(LinkR)`
    color: #33BB30;
    font-size: 22px;
    text-decoration: none;
    font-weight: 600;
    /* @media screen and (max-width: 768px){
        text-align: center;
    } */
`