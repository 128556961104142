import React, {useState} from 'react'
import FAQSection from '../components/FAQSection';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar'
import { Overlay } from './GeneralStyles'

const FAQPage = () => {

    const [isOpen, setIsOpen] = useState(false);
    
    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
                { isOpen && <Overlay /> }
            <Navbar toggle={toggle} />
            <FAQSection/>
        </>
    )
}

export default FAQPage
